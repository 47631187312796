import PropTypes from 'prop-types'

import { InstagramIcon, TiktokIcon, YoutubeIcon } from 'components/Icons'

const GetVendorIcon = ({ vendor, size }) => {
    switch (vendor) {
        case 'instagram': return <InstagramIcon size={size}/>
        case 'youtube': return <YoutubeIcon size={size}/>
        case 'tiktok': return <TiktokIcon size={size}/>
        default: return null
    }
}

export default GetVendorIcon

GetVendorIcon.propTypes = {
    vendor: PropTypes.string.isRequired,
    size: PropTypes.number,
}

GetVendorIcon.defaultProps = {
    size: 20,
}

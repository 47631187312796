import PropTypes from 'prop-types'

import Text from 'components/Text'

import { PieChart } from 'recharts/lib/chart/PieChart'
import { Cell } from 'recharts/lib/component/Cell'
import { Pie } from 'recharts/lib/polar/Pie'

const styles = {
    wrapper: {
        padding: 40,
        background: '#fff',
        borderRadius: 15,
        boxShadow: '0px 0px 46.6167px rgba(205, 213, 231, 0.5)',
        position: 'relative',
    },
    piechartWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '85%',
    },
    labelWrapper: {
        marginTop: 30,
        marginBottom: 30,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'absolute',
        bottom: 10,
        right: 30,
        left: 30,
    },
    label: {
        color: '#00A6E8',
        alignItems: 'center',
        display: 'flex',
    },
    maleDot: {
        height: 10,
        width: 10,
        background: '#2772FF',
        borderRadius: 10,
        marginRight: 5,
    },
    femaleDot: {
        height: 10,
        width: 10,
        background: '#F18DED',
        borderRadius: 10,
        marginRight: 5,
    },
    otherDot: {
        height: 10,
        width: 10,
        background: '#A8B0C0',
        borderRadius: 10,
        marginRight: 5,
    },
}

const GenderCard = ({ data }) => {
    if (!data) return null

    // const modifiedData = [
    //     { name: 'female', value: data.female },
    //     { name: 'male', value: data.male },
    // ]

    return (
        <div style={styles.wrapper}>
            <Text
                children={'Gender'}
                size={'h5'}
                weight={'semiBold'}
            />
            <div style={styles.piechartWrapper}>
                <PieChart width={300} height={275}>
                    <Pie
                        data={[
                            { name: 'female', value: data.female },
                            { name: 'male', value: data.male },
                            { name: 'other', value: data.other },
                        ]}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        <Cell key={'cell-female'} fill={'#F18DED'} />
                        <Cell key={'cell-male'} fill={'#2772FF'} />
                        <Cell key={'cell-other'} fill={'#A8B0C0'} />
                    </Pie>
                </PieChart>
            </div>
            <div style={styles.labelWrapper}>
                <div style={styles.label}>
                    <div style={styles.maleDot} />
                    <Text
                        size={'h6'}
                        weight={'medium'}
                        color={'black'}
                        children={`${data.male.toFixed(0)}% Male`}
                    />
                </div>
                <div style={styles.label}>
                    <div style={styles.femaleDot} />
                    <Text
                        size={'h6'}
                        weight={'medium'}
                        color={'black'}
                        children={`${data.female.toFixed(0)}% Female`}
                    />
                </div>
                {data.other && (
                    <div style={styles.label}>
                        <div style={styles.otherDot} />
                        <Text
                            size={'h6'}
                            weight={'medium'}
                            color={'black'}
                            children={`${data.other.toFixed(0)}% Other`}
                        />
                    </div>
                )
                }
            </div>

        </div>
    )
}

GenderCard.propTypes = {
    data: PropTypes.object,
}

GenderCard.defaultProps = {
    data: {},
}

export default GenderCard

import PropTypes from 'prop-types'

const styles = {
    wrapper: {
        display: 'grid',
        gridColumnGap: 20,
        gridRowGap: 20,
    },
}

const MetricGrid = ({ children, columns }) => {
    return (
        <div style={{
            ...styles.wrapper,
            gridTemplateColumns: [...new Array(columns)].map(i => '1fr').join(' '),
        }}>
            {children}
        </div>
    )
}

MetricGrid.propTypes = {
    columns: PropTypes.number,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
}

MetricGrid.defaultProps = {
    columns: 2,
}

export default MetricGrid


import PropTypes from 'prop-types'

import { convertNumber, makePercentage } from 'lib/numbers'
import Icon from 'components/Iconly'
import Text from 'components/Text'

const styles = {
    wrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: 16,
        padding: 16,
    },
    metricWrapper: {
        display: 'flex',
        alignItems: 'center',
        background: '#fff',
        boxShadow: '0px 0px 25px rgba(205, 213, 231, 0.3)',
        borderRadius: 10,
        padding: '15px 0px 15px 15px',
        width: '100%',
    },
    metricIconWrapper: {
        borderRadius: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
        width: 40,
        fontSize: 20,
    },
}

const MetricCard = ({
    label,
    value,
    icon,
    iconBg,
    iconColor,
}) => {
    return (
        <div style={styles.metricWrapper}>
            <div style={{
                ...styles.metricIconWrapper,
                backgroundColor: iconBg && iconBg.length ? iconBg : '#F2F8FF',
                color: iconColor && iconColor.length ? iconColor : '#2772FF',
            }}>
                <Icon type={icon} bold/>
            </div>
            <div style={{ width: 10 }}/>
            <div>
                <Text
                    children={label}
                    color={'text'}
                    size={'p2'}
                />
                <Text
                    children={value}
                    color={'black'}
                    weight={'bold'}
                    size={'h5'}
                />
            </div>
        </div>
    )
}

const OverviewGrid = ({ views, likes, comments, engagement }) => {
    return (
        <div style={styles.wrapper}>
            <MetricCard
                value={convertNumber(views)}
                label={'Views'}
                icon={'Show'}
            />
            <MetricCard
                value={makePercentage(engagement)}
                label={'Engagement'}
                icon={'Star'}
            />
            <MetricCard
                value={convertNumber(likes)}
                label={'Likes'}
                icon={'Heart'}
            />
            <MetricCard
                value={convertNumber(comments)}
                label={'Comments'}
                icon={'Chat'}
            />
        </div>
    )
}

OverviewGrid.propTypes = {
    views: PropTypes.number,
    likes: PropTypes.number,
    comments: PropTypes.number,
    engagement: PropTypes.number,
}

OverviewGrid.defaultProps = {
    views: 0,
    likes: 0,
    comments: 0,
    engagement: 0,
}

MetricCard.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    icon: PropTypes.string.isRequired,
    iconBg: PropTypes.string,
    iconColor: PropTypes.string,
}

MetricCard.defaultProps = {
    iconBg: '#F2F8FF',
    iconColor: '#2772FF',
}

export default OverviewGrid

import PropTypes from 'prop-types'
import dayjs from 'dayjs'

import Image from 'components/Image'
import Text from 'components/Text'
import Icon from 'components/Iconly'

const styles = {
    wrapper: {
        boxShadow: '0px 0px 25px rgba(205, 213, 231, 0.3)',
        background: '#fff',
        borderRadius: 10,
        padding: 10,
        display: 'flex',
        height: '100%',
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
    },
    infoWrapper: {
        width: '50%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        padding: '5px 0',
    },
}
const ContentInfo = ({ vendor, pic, date, channelName, channelPic, url }) => {
    return (
        <div style={styles.wrapper}>
            <Image
                src={pic}
                height={100}
                width={100}
                borderRadius={10}
                objectFit={'cover'}
                fallbackType={'contentV1'}
            />
            <div style={{ width: 16 }}/>
            <div style={styles.infoWrapper}>
                <div style={styles.flex}>
                    <Image
                        src={channelPic}
                        height={26}
                        width={26}
                        borderRadius={'100%'}
                        objectFit={'cover'}
                    />
                    <div style={{ minWidth: 5, width: 5 }}/>
                    <Text
                        size={'p'}
                        weight={'medium'}
                        children={`@${channelName}`}
                        truncated
                        wrapperStyle={{ width: '100%' }}
                    />
                </div>
                <Text
                    children={(
                        <div style={{ display: 'flex' }}>
                            <Icon type={'Calendar'} bold style={{ fontSize: 14 }}/>
                            <div style={{ width: 5 }}/>
                            <span>{dayjs(date).format('D MMM, YYYY')}</span>
                        </div>
                    )}
                    onClick={() => window.open(url)}
                    color={'grey2'}
                    weight={'bold'}
                    size={'p2'}
                />
                <Text
                    children={`View on ${vendor}`}
                    onClick={() => window.open(url)}
                    color={'link'}
                    weight={'semiBold'}
                    size={'p2'}
                />
            </div>
        </div>
    )
}

ContentInfo.propTypes = {
    pic: PropTypes.string,
    vendor: PropTypes.string,
    date: PropTypes.string,
    channelName: PropTypes.string,
    channelPic: PropTypes.string,
    url: PropTypes.string,
}

ContentInfo.defaultProps = {
    pic: '',
    vendor: '',
    date: '',
    channelName: '',
    channelPic: '',
    url: '',
}

export default ContentInfo

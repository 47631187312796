
import PropTypes from 'prop-types'

import Text from 'components/Text'
import Contents from './Contents'
import OverviewGrid from './OverviewGrid'
import Header from './Header'

const styles = {
    wrapper: {
        backgroundColor: '#F8F9FA',
        height: '100%',
        width: '100%',
    },
}

const MobileLayout = ({ data, onContentClick }) => {
    if (!data) return 'nothing to show'

    return (
        <div style={styles.wrapper}>
            <Header />
            <div style={{ height: 60 }}/>
            <Text
                wrapperStyle={{ marginLeft: 16 }}
                weight={'semiBold'}
                children={'Report Overview'}
            />
            <OverviewGrid
                views={data.views}
                likes={data.likes}
                comments={data.comments}
                engagement={data.engagement}
            />
            <div style={{ height: 40 }}/>
            <Text
                wrapperStyle={{ marginLeft: 16 }}
                weight={'semiBold'}
                children={'Content in Report'}
            />
            <div style={{ height: 15 }}/>
            <Contents
                data={data.content}
                onDisclose={onContentClick}
            />
            <div style={{ height: 30 }}/>
        </div>
    )
}

MobileLayout.propTypes = {
    data: PropTypes.object,
    onContentClick: PropTypes.func,
}

MobileLayout.defaultProps = {
    data: null,
    onContentClick: () => null,
}

export default MobileLayout

import Text from 'components/Text'
import PropTypes from 'prop-types'

const AnalyticsTitle = ({ title }) => {
    return (
        <>
            <Text
                color={'black'}
                weight={'bold'}
                size={'h3'}
                children={title}
            />
            <div style={{ height: 35 }}/>
        </>
    )
}

AnalyticsTitle.propTypes = {
    title: PropTypes.string.isRequired,
}

export default AnalyticsTitle

import PropTypes from 'prop-types'

import AnalyticsTitle from '../AnalyticsTitle'
import MetricGrid from 'components/MetricGrid'
import AudienceCard from './AudienceCard'
import GenderCard from './GenderCard'

const Audience = ({ genders, ages, cities, countries, sources, sourcesOS, devices }) => {
    const sortDesc = (arr) => arr.sort((a, b) => (a.value > b.value ? -1 : 1))

    return (
        <div>
            <div style={{ height: 60 }}/>
            <AnalyticsTitle title={'Audience Overview'}/>
            <MetricGrid>
                {genders && genders.male
                    ? <GenderCard
                        data={genders}
                    />
                    : null
                }
                {ages && ages.length
                    ? <AudienceCard
                        title={'Age'}
                        data={sortDesc(ages)}
                    />
                    : null
                }
                {countries && countries.length
                    ? <AudienceCard
                        title={'Countries'}
                        data={countries}
                    />
                    : null
                }
                {cities && cities.length
                    ? <AudienceCard
                        title={'Cities'}
                        data={cities}
                    />
                    : null
                }
                {sources && sources.length
                    ? <AudienceCard
                        title={'Traffic sources'}
                        data={sources}
                    />
                    : null
                }
                {sourcesOS && sourcesOS.length
                    ? <AudienceCard
                        title={'Operating systems'}
                        data={sourcesOS}
                    />
                    : null
                }
                {devices && devices.length
                    ? <AudienceCard
                        title={'Devices'}
                        data={devices}
                    />
                    : null
                }
            </MetricGrid>
        </div>
    )
}

Audience.propTypes = {
    genders: PropTypes.object,
    ages: PropTypes.array,
    cities: PropTypes.array,
    countries: PropTypes.array,
    sources: PropTypes.array,
    sourcesOS: PropTypes.array,
    devices: PropTypes.array,

}

Audience.defaultProps = {
    genders: {},
    ages: [],
    cities: [],
    countries: [],
    sources: [],
    sourcesOS: [],
    devices: [],
}

export default Audience

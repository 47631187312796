
import PropTypes from 'prop-types'
import { makePercentage, convertNumber } from 'lib/numbers'
import Icon from 'components/Iconly'
import Text from 'components/Text'

const styles = {
    wrapper: {
        margin: '0 -15px',
        padding: '0 15px',
        overflowX: 'scroll',
        overflowY: 'hidden',
        WebkitOverflowScrolling: 'touch',
    },
    metricsWrapper: {
        display: 'grid',
        gridColumnGap: 10,
        gridTemplateColumns: '1fr 1fr 1fr',
        // width: '100%',
        width: 'fit-content',
    },

    cardWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        // border: '1px solid #DAE6FD',
        background: '#fff',
        // boxShadow: '0px 0px 10px rgba(205, 213, 231, 0.4)',
        borderRadius: 10,
        padding: 15,
        width: '33vw',
        maxWidth: 250,
        // marginRight: 15,
        boxShadow: '0px 0px 25px rgba(205, 213, 231, 0.3)',
        // display: 'inline-block',
    },
    cardIconWrapper: {
        borderRadius: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 35,
        width: 35,
        fontSize: 18,
        marginBottom: 15,
    },
}

const Card = ({ label, value, icon, iconBg, iconColor }) => {
    return (
        <div style={styles.cardWrapper}>
            <div style={{
                ...styles.cardIconWrapper,
                backgroundColor: iconBg && iconBg.length ? iconBg : '#ECFCFF',
                color: iconColor && iconColor.length ? iconColor : '#68ADBA',
            }}>
                <Icon type={icon} bold/>
            </div>
            <div style={{ width: 10 }}/>
            <div>
                <Text
                    children={label}
                    color={'grey2'}
                    size={'p2'}
                />
                <Text
                    children={value}
                    color={'black'}
                    weight={'bold'}
                    size={'h4'}
                />
            </div>
        </div>
    )
}

const MainMetrics = ({ views, shares, likes, viewRate, comments, engagement }) => {
    return (
        <div style={styles.wrapper}>
            <div style={styles.metricsWrapper}>
                <Card
                    value={convertNumber(views)}
                    label={'Views'}
                    icon={'Show'}
                    iconBg={'#F5F9FF'}
                    iconColor={'#2772FF'}
                />
                <Card
                    value={makePercentage(viewRate)}
                    label={'View Rate'}
                    icon={'Logout'}
                    iconBg={'#F5F9FF'}
                    iconColor={'#2772FF'}
                />
                <Card
                    value={makePercentage(engagement)}
                    label={'Engagement'}
                    icon={'Star'}
                    iconBg={'#F5F9FF'}
                    iconColor={'#2772FF'}
                />
            </div>
            <div style={{ height: 10 }}/>
            <div style={styles.metricsWrapper}>
                <Card
                    value={convertNumber(likes)}
                    label={'Likes'}
                    icon={'Heart'}
                    iconBg={'#F5F9FF'}
                    iconColor={'#2772FF'}
                />
                <Card
                    value={convertNumber(comments)}
                    label={'Comments'}
                    icon={'Chat'}
                    iconBg={'#F5F9FF'}
                    iconColor={'#2772FF'}
                />
                <Card
                    value={convertNumber(shares)}
                    label={'Shares'}
                    icon={'Upload'}
                    iconBg={'#F5F9FF'}
                    iconColor={'#2772FF'}
                />
            </div>
        </div>
    )
}

MainMetrics.propTypes = {
    comments: PropTypes.number,
    views: PropTypes.number,
    engagement: PropTypes.number,
    likes: PropTypes.number,
    shares: PropTypes.number,
    viewRate: PropTypes.number,
}

MainMetrics.defaultProps = {
    comments: 0,
    shares: 0,
    followers: 0,
    engagement: 0,
    likes: 0,
    views: 0,
    viewRate: 0,
}

Card.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    icon: PropTypes.string.isRequired,
    iconBg: PropTypes.string.isRequired,
    iconColor: PropTypes.string.isRequired,
}

export default MainMetrics

import PropTypes from 'prop-types'
import { useState } from 'react'

import { convertDate } from 'lib/dates'
import Text from 'components/Text'
import Image from 'components/Image'

import AnalyticsTitle from '../AnalyticsTitle'
import BarChart from './ChartBar'
import Tabs from './Tabs'

const styles = {
    wrapper: {
        background: '#fff',
        // boxShadow: '0px 0px 10px rgba(205, 213, 231, 0.4)',
        // border: '1px solid #EBF0F9',
        paddingBottom: 15,
        paddingTop: 35,
        borderRadius: 15,
        boxShadow: '0px 0px 25px rgba(205, 213, 231, 0.3)',
    },
    postsImageWrapper: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'space-around',
        paddingLeft: 53,
        paddingRight: 5,
        overflow: 'hidden',
    },
    imageWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    title: {
        marginLeft: 35,
    },
    date: {
        fontSize: 12,
        marginTop: 5,
        width: 0,
        whiteSpace: 'nowrap',
        color: '#BCC6D9',
    },
}
const PostComparisons = ({ posts, tabs, vendor }) => {
    const [ activeTab, setActiveTab ] = useState(tabs[0] && tabs[0].key)

    return (
        <div style={styles.wrapper}>
            <div style={styles.title}>
                <AnalyticsTitle title={`This post vs last ${posts.length} posts`} />
            </div>
            <Tabs
                tabs={tabs}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
                justifyContent={'space-between'}
            />
            <div style={{ height: 30 }}/>
            <BarChart data={posts} dataKey={activeTab} hideDate />
            <div style={styles.postsImageWrapper}>
                {posts.map(item => {
                    return (
                        <div
                            key={item.id}
                            style={{
                                ...styles.imageWrapper,
                                height: vendor === 'tiktok' ? 130 : 90,
                            }}>
                            <Image
                                height={vendor === 'tiktok' ? 96 : 54}
                                width={54}
                                src={item.pic}
                                borderRadius={5}
                                objectFit={'cover'}
                                fallbackType={'contentV1'}
                            />
                            <Text
                                size={'p2'}
                                color={'text'}
                                weight={'medium'}
                                children={convertDate(item.date)}
                                wrapperStyle={{ marginTop: 7.5 }}
                            />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

PostComparisons.propTypes = {
    posts: PropTypes.arrayOf(PropTypes.shape({
        views: PropTypes.number,
        comments: PropTypes.number,
        likes: PropTypes.number,
    })),
    tabs: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
    })),
    vendor: PropTypes.string.isRequired,
}

PostComparisons.defaultProps = {
    posts: [],
    tabs: [],
}

export default PostComparisons

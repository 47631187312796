
import PropTypes from 'prop-types'

import { convertTimeFromNow } from 'lib/dates'
import PageDrawer from 'components/PageDrawer'
import MobileLayout from 'components/MobileLayout'
import Text from 'components/Text'

import ErrorUI from '../../ErrorUI'
import LoadingUI from '../../LoadingUI'
import InstagramUI from './InstagramUI'
import YoutubeUI from './YoutubeUI'
import TiktokUI from './TiktokUI'

const ReportContentMobile = ({ isOpen, isError, isLoading, data, close, vendor }) => {
    const body = () => {
        if (isError) {
            return (
                <ErrorUI
                    title={'Oops, this report content doesn\'t exist'}
                    body={' '}
                />
            )
        }

        if (isLoading) {
            return <LoadingUI />
        }

        switch (vendor) {
            case 'instagram': return <InstagramUI data={data}/>
            case 'youtube': return <YoutubeUI data={data} />
            case 'tiktok': return <TiktokUI data={data} />
            default: return 'need UI for ' + vendor
        }
    }

    const getTitle = () => {
        switch (vendor) {
            case 'instagram': return 'Instagram'
            case 'tiktok': return 'TikTok'
            case 'youtube': return 'YouTube'
            default: return 'Unknown vendor'
        }
    }

    return (
        <PageDrawer direction={'bottom'} open={isOpen} onClose={close}>
            <MobileLayout
                withHeader
                headerClose
                headerBody={(
                    data && data.lastUpdated
                        ? (
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                <Text
                                    children={`Last updated ${convertTimeFromNow(data.lastUpdated)} ago`}
                                    size={'p2'}
                                    weight={'medium'}
                                    color={'grey2'}
                                />
                            </div>
                        )
                        : null
                )}
                // bodyNoPadding
                bodyScroll
                bodyBackground={'secondary'}
                headerCloseHandler={close}
                headerTitle={getTitle()}
                children={body()}
            />
        </PageDrawer>
    )
}

ReportContentMobile.propTypes = {
    isOpen: PropTypes.bool,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    data: PropTypes.object,
    close: PropTypes.func,
    vendor: PropTypes.string,
}

ReportContentMobile.defaultProps = {
    isOpen: false,
    isError: false,
    isLoading: false,
    data: null,
    close: () => null,
    vendor: null,
}

export default ReportContentMobile

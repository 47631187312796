
import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'

import Icon from 'components/Iconly'
import Text from 'components/Text'

const styles = {
    wrapper: {
        height: '100%',
        width: '100%',
        position: 'relative',
    },
    header: (height = 50) => ({
        display: 'flex',
        alignItems: 'center',
        paddingRight: 15,
        paddingLeft: 15,
        height: height,
        width: '100%',
        backgroundColor: '#fff',
    }),
    headerCenter: {
        justifyContent: 'center',
    },
    headerSpaceBetween: {
        justifyContent: 'space-between',
    },
    headerBack: {
        padding: 10,
        marginRight: 5,
        marginLeft: -10,
    },
    body: {
        height: '100%',
        padding: 15,
        width: '100%',
        backgroundColor: '#fff',
        overflowY: 'scroll',
        WebkitOverflowScrolling: 'touch',
    },
    bodyNoScroll: {
        overflowY: 'hidden',
        overflowX: 'hidden',
    },
    bodyWithFooterAndHeader: (headerHeight = 50, footerHeight = 50) => ({
        height: `calc(100% - ${headerHeight + footerHeight}px)`,
    }),
    bodyWithFooter: (height = 50) => ({
        height: `calc(100% - ${height}px)`,
    }),
    bodyWithHeader: (height = 50) => ({
        height: `calc(100% - ${height}px)`,
    }),
    bodyNoPadding: {
        padding: 0,
    },
    bodySecondaryBg: {
        backgroundColor: '#F8F9FA',
    },
}

const MobileLayout = ({
    withHeader,
    withFooter,
    bodyScroll,
    bodyNoPadding,
    bodyBackground,
    bodyIgnoreExtraSpace,
    headerTitle,
    headerBack,
    headerClose,
    headerCloseHandler,
    headerBackHandler,
    headerSpaceBetween,
    headerCenter,
    headerBody,
    children,
    headerHeight,
    footerHeight,
    ignoreTopSafeArea,
}) => {
    const bodyRef = useRef(null)

    useEffect(() => {
        const func = (e) => {
            const event = new CustomEvent('mobilelayout-body-scroll', {
                detail: {
                    scrollHeight: bodyRef.current.scrollHeight,
                    scrollWidth: bodyRef.current.scrollWidth,
                    scrollTop: bodyRef.current.scrollTop,
                    scrollLeft: bodyRef.current.scrollLeft,
                },
            })
            document.dispatchEvent(event)
        }
        bodyRef.current.addEventListener('scroll', func)

        return () => {
            bodyRef && bodyRef.current && bodyRef.current.removeEventListener('scroll', func)
        }
    }, [])

    const wrapperStyles = {
        ...styles.wrapper,
        ...(ignoreTopSafeArea ? { paddingTop: 0 } : {}),
    }

    const bodyStyles = {
        ...styles.body,
        ...(withHeader && withFooter ? styles.bodyWithFooterAndHeader(headerHeight, footerHeight) : {}),
        ...(withHeader && !withFooter ? styles.bodyWithHeader(headerHeight) : {}),
        ...(!withHeader && withFooter ? styles.bodyWithFooter(footerHeight) : {}),
        ...(!bodyScroll ? styles.bodyNoScroll : {}),
        ...(bodyNoPadding ? styles.bodyNoPadding : {}),
        ...(bodyBackground === 'secondary' ? styles.bodySecondaryBg : {}),
    }

    const headerStyles = {
        ...styles.header(headerHeight),
        ...(headerSpaceBetween ? styles.headerSpaceBetween : {}),
        ...(headerCenter ? styles.headerCenter : {}),
    }

    return (
        <div style={wrapperStyles}>
            {!withHeader
                ? null
                : (
                    <>
                        <div style={headerStyles}>
                            {headerBack
                                ? (
                                    <div
                                        style={styles.headerBack}
                                        onClick={headerBackHandler}
                                    >
                                        <div style={{ height: 2.5 }}/>
                                        <Icon
                                            type={'Arrow-Left'}
                                            style={{ fontSize: 24 }}
                                        />
                                    </div>
                                )
                                : null}
                            {headerClose
                                ? (
                                    <div
                                        style={styles.headerBack}
                                        onClick={headerCloseHandler}
                                    >
                                        <div style={{ height: 2.5 }}/>
                                        <Icon
                                            type={'Arrow-Down-2'}
                                            style={{ fontSize: 25 }}
                                        />
                                    </div>
                                )
                                : null}
                            {headerTitle ? (<Text size={'h6'} weight={'bold'}>{headerTitle}</Text>) : null}
                            {headerBody || null}
                        </div>
                    </>
                )}
            <div style={bodyStyles} ref={bodyRef}>
                {children}
                {bodyIgnoreExtraSpace ? null : (<div style={{ height: 30 }}/>)}
            </div>
        </div>
    )
}

MobileLayout.propTypes = {
    headerBack: PropTypes.bool,
    headerBackHandler: PropTypes.func,
    headerClose: PropTypes.bool,
    headerCloseHandler: PropTypes.func,
    headerTitle: PropTypes.string,
    headerSpaceBetween: PropTypes.bool,
    headerCenter: PropTypes.bool,
    headerBody: PropTypes.any,
    withFooter: PropTypes.bool,
    withHeader: PropTypes.bool,
    bodyScroll: PropTypes.bool,
    bodyNoPadding: PropTypes.bool,
    bodyBackground: PropTypes.string,
    bodyIgnoreExtraSpace: PropTypes.bool,
    children: PropTypes.any.isRequired,
    headerHeight: PropTypes.number,
    footerHeight: PropTypes.number,
    ignoreTopSafeArea: PropTypes.bool,
}

MobileLayout.defaultProps = {
    withFooter: false,
    withHeader: false,
    bodyScroll: true,
    bodyNoPadding: false,
    bodyBackground: 'default',
    bodyIgnoreExtraSpace: false,
    headerSpaceBetween: false,
    headerCenter: false,
    headerBack: false,
    headerBackHandler: () => null,
    headerClose: false,
    headerCloseHandler: () => null,
    headerTitle: null,
    headerBody: null,
    headerHeight: 50,
    footerHeight: 50,
    ignoreTopSafeArea: false,
}

export default MobileLayout


import PropTypes from 'prop-types'

const ShareIcon = ({ color, size }) => {
    const params = {
        fill: 'none',
        stroke: 'none',
        xmlns: 'http://www.w3.org/2000/svg',
        viewBox: '0 0 24 24',
        height: String(size) + 'px',
        width: String(size) + 'px',
    }

    const body = (
        <>
            <path
                d="M13.0068 6.84439C13.0466 6.82004 13.0643 6.77135 13.0494 6.7271C12.905 6.29909 12.8267 5.84055 12.8267 5.36352C12.8267 3.00883 14.7356 1.09995 17.0903 1.09995C19.4449 1.09995 21.3538 3.00883 21.3538 5.36352C21.3538 7.71821 19.4449 9.62708 17.0903 9.62708C15.9166 9.62708 14.8539 9.15302 14.0829 8.38569C14.0502 8.35318 13.9995 8.34721 13.9602 8.37125L10.4468 10.5183C10.4069 10.5427 10.3892 10.5914 10.4042 10.6356C10.5486 11.0636 10.6269 11.5222 10.6269 11.9993C10.6269 12.4532 10.556 12.8903 10.4247 13.3004C10.4108 13.3438 10.4281 13.3912 10.4667 13.4155L13.9671 15.6195C14.0065 15.6442 14.0577 15.6386 14.0908 15.6059C14.8612 14.8432 15.9206 14.3724 17.0903 14.3724C19.4449 14.3724 21.3538 16.2812 21.3538 18.6359C21.3538 20.9906 19.4449 22.8995 17.0903 22.8995C14.7356 22.8995 12.8267 20.9906 12.8267 18.6359C12.8267 18.1549 12.9063 17.6928 13.053 17.2617C13.0679 17.2179 13.0508 17.1696 13.0116 17.1449L9.5416 14.9601C9.50169 14.935 9.44963 14.9412 9.41671 14.9749C8.64219 15.7696 7.56044 16.2628 6.36331 16.2628C4.00861 16.2628 2.09976 14.3539 2.09976 11.9993C2.09976 9.64457 4.00861 7.7357 6.36331 7.7357C7.5369 7.7357 8.59958 8.20974 9.37065 8.97704C9.40332 9.00955 9.45399 9.01552 9.49333 8.99148L13.0068 6.84439ZM17.0903 2.89995C15.7297 2.89995 14.6267 4.00293 14.6267 5.36352C14.6267 6.7241 15.7297 7.82708 17.0903 7.82708C18.4508 7.82708 19.5538 6.7241 19.5538 5.36352C19.5538 4.00293 18.4508 2.89995 17.0903 2.89995ZM6.36331 9.5357C5.00273 9.5357 3.89976 10.6387 3.89976 11.9993C3.89976 13.3598 5.00273 14.4628 6.36331 14.4628C7.72389 14.4628 8.82686 13.3598 8.82686 11.9993C8.82686 10.6387 7.72389 9.5357 6.36331 9.5357ZM17.0903 16.1724C15.7297 16.1724 14.6267 17.2754 14.6267 18.6359C14.6267 19.9965 15.7297 21.0995 17.0903 21.0995C18.4508 21.0995 19.5538 19.9965 19.5538 18.6359C19.5538 17.2754 18.4509 16.1724 17.0903 16.1724Z"
                fill={color}
                stroke="#FFFFFF"
                strokeWidth="0.2"
                strokeMiterlimit="10"
                strokeLinejoin="round"
            />
        </>
    )

    return <svg {...params} children={body} />
}

ShareIcon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
}

ShareIcon.defaultProps = {
    color: '#1B1F27',
    size: 25,
}

export default ShareIcon

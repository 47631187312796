import logo from 'resources/images/logo-black.png'

const styles = {
    wrapper: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 5,
        background: '#fff',
        height: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px -4px 35px rgba(205, 213, 231, 0.4)',
    },
}

const Header = () => {
    return (
        <div>
            <div style={styles.wrapper}>
                <img
                    src={logo}
                    alt={'mysocial'}
                    style={{ height: 25 }}
                />
            </div>
            <div style={{ height: 50 }}/>
        </div>
    )
}

export default Header


import PropTypes from 'prop-types'

import Text from 'components/Text'
import OverviewGrid from './OverviewGrid'
import Header from './Header'
import Contents from './Contents2'

const styles = {
    wrapper: {
        height: '100%',
        width: '100%',
        // minHeight: '100%',
        // overflow: 'scroll',
        // height: '100%',
        paddingBottom: 30,
        background: 'linear-gradient(94.16deg, #F4F6F9 0.18%, #F5F7FA 93.48%)',
        overflowY: 'scroll',

    },
    innerWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingLeft: 30,
        paddingRight: 30,
    },
    innerInnerWrapper: {
        maxWidth: 980,
        minWidth: 980,
    },
}

const DesktopLayout = ({ data, onContentClick }) => {
    return (
        <div style={styles.wrapper}>
            <Header />
            <div style={{ height: 60 }}/>
            <div style={styles.innerWrapper}>
                <div style={styles.innerInnerWrapper}>
                    <Text
                        wrapperStyle={{ marginLeft: 16 }}
                        weight={'bold'}
                        size={'h4'}
                        children={'Report Overview'}
                    />
                    <div style={{ height: 16 }}/>
                    <OverviewGrid
                        views={data.views}
                        likes={data.likes}
                        comments={data.comments}
                        engagement={data.engagement}
                    />
                    <div style={{ height: 40 }}/>
                    <Text
                        wrapperStyle={{ marginLeft: 16 }}
                        weight={'bold'}
                        size={'h4'}
                        children={'Content in Report'}
                    />
                    <div style={{ height: 16 }}/>
                    <Contents
                        data={data.content}
                        onDisclose={onContentClick}
                    />
                </div>
            </div>
        </div>
    )
}

DesktopLayout.propTypes = {
    data: PropTypes.object,
    onContentClick: PropTypes.func,
}

DesktopLayout.defaultProps = {
    data: null,
    onContentClick: () => null,
}

export default DesktopLayout

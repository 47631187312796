
import PropTypes from 'prop-types'
import { LineChart } from 'recharts/lib/chart/LineChart'
import { Line } from 'recharts/lib/cartesian/Line'
import { XAxis } from 'recharts/lib/cartesian/XAxis'
import { YAxis } from 'recharts/lib/cartesian/YAxis'
import { CartesianGrid } from 'recharts/lib/cartesian/CartesianGrid'
import { Tooltip } from 'recharts/lib/component/Tooltip'
import { ResponsiveContainer } from 'recharts/lib/component/ResponsiveContainer'

import { makePercentage } from 'lib/numbers'
import Text from 'components/Text'

const styles = {
    wrapper: {
        paddingRight: 15,
        position: 'relative',
    },
    tooltipContent: {
        border: 'none',
        margin: 0,
        padding: '10px 10px',
        borderRadius: 20,
        backgroundColor: '#fff',
        boxShadow: '0px -4px 20px rgba(205, 213, 231, 0.25)',
    },
    tooltipItem: {
        fontSize: 14,
        fontWeight: 700,
        display: 'inline-block',
        color: '#2772FF',
    },
    tooltipLabel: {
        fontSize: 12,
        color: '#BCC6D9',
    },
    tick: {
        fill: '#BCC6D9',
        fontSize: 12,
    },
    negativeColor: '#FFD9DF',
    positiveColor: '#DAE7FF',
    noData: {
        position: 'absolute',
        zIndex: 50,
        top: '40%',
        left: 0,
        right: 0,
        margin: '0 auto',
        textAlign: 'center',
    },
}

const ChartLine = ({ data }) => {
    return (
        <div style={styles.wrapper}>
            {data.length
                ? null
                : (
                    <div style={styles.noData}>
                        <Text
                            children={'no data available'}
                            color={'grey3'}
                            weight={'semiBold'}
                            size={'p'}
                        />
                    </div>
                )}
            <ResponsiveContainer height={250} width={'100%'}>
                <LineChart data={data}>
                    <XAxis
                        dataKey={'time'}
                        axisLine={false}
                        tickLine={false}
                        tick={styles.tick}
                        height={40}
                        tickMargin={20}
                        angle={-90}
                        scale={''}
                    />
                    <YAxis
                        width={40}
                        tickFormatter={(value) => makePercentage(value)}
                        axisLine={false}
                        tickLine={false}
                        tick={styles.tick}
                        tickCount={6}
                    />
                    <CartesianGrid
                        vertical={false}
                        strokeDasharray={'15'}
                        stroke={'#EBF0F9'}
                        strokeWidth={0.5}
                    />
                    <Tooltip
                        separator={''}
                        contentStyle={styles.tooltipContent}
                        cursor={{ fill: '#EBF0F9' }}
                        itemStyle={styles.tooltipItem}
                        labelStyle={styles.tooltipLabel}
                        formatter={(value, name, props) => {
                            if (value === null) return null
                            return makePercentage(value)
                        }}
                        labelFormatter={(value) => value}
                    />
                    <Line
                        type={'natural'}
                        dataKey={'value'}
                        stroke={'#2772FF'}
                        dot={false}
                        activeDot={{ r: 5, stroke: '#fff' }}
                        name={' '}
                        fill={'#2772FF'}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

ChartLine.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        data: PropTypes.string,
        value: PropTypes.number,
    })),
    dataKey: PropTypes.string,
}

ChartLine.defaultProps = {
    data: [],
    dataKey: 'value',
}

export default ChartLine


import PropTypes from 'prop-types'

import Image from 'components/Image'
import VendorIcon from 'components/VendorIcon'
import Text from 'components/Text'
import Icon from 'components/Iconly'
import { convertNumber } from 'lib/numbers'
import { capitalizeString } from 'lib/strings'
import Button from 'components/Button'

const styles = {
    wrapper: {
        padding: 16,
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: 16,
    },
    itemWrapper: {
        boxShadow: '0px 0px 25px rgba(205, 213, 231, 0.3)',
        background: '#fff',
        marginBottom: 16,
        borderRadius: 10,
        transition: 'transform 0.1s',
        cursor: 'pointer',
    },
    channelWrapper: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    metricWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    contentMetricWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 18,
        color: '#A8B0C0',
    },
    contentInfoWrapper: {
        padding: 20,
    },
    button: {
        height: 50,
        borderRadius: 10,
    },
}

const ContentMetric = ({ value, iconType }) => {
    return (
        <div style={styles.contentMetricWrapper}>
            <Icon
                type={iconType}
                bold/>
            <div style={{ width: 5 }}/>
            <Text
                size={'h5'}
                color={'grey2'}
                weight={'bold'}
                children={value}
            />
        </div>
    )
}

const ContentItem = ({ item, onDisclose }) => {
    let el = null

    return (
        <div
            ref={e => (el = e)}
            onMouseEnter={() => {
                el.style.opacity = 0.8
                el.style.transform = 'scale(0.95)'
            }}
            onMouseLeave={() => {
                el.style.opacity = 1
                el.style.transform = 'scale(1)'
            }}
            style={styles.itemWrapper}
            onClick={onDisclose}
        >
            <Image
                src={item.pic}
                height={304}
                width={304}
                borderRadius={'10px 10px 0px 0px'}
                objectFit={'cover'}
                fallbackType={'contentV1'}
            />
            <div style={{ height: 10 }}/>
            <div style={styles.contentInfoWrapper}>
                <div style={styles.channelWrapper}>
                    <VendorIcon vendor={item.vendor} size={25}/>
                    <div style={{ width: 8 }}/>
                    <Text
                        size={'h5'}
                        weight={'semiBold'}
                        children={capitalizeString(item.channelName || item.vendor)}
                    />
                </div>
                <div style={{ height: 32 }}/>
                <div style={styles.metricWrapper}>
                    <ContentMetric
                        iconType={'Show'}
                        value={convertNumber(item.views)}/>
                    <ContentMetric
                        iconType={'Heart'}
                        value={convertNumber(item.likes)}
                    />
                    <ContentMetric
                        iconType={'Chat'}
                        value={convertNumber(item.comments)}/>
                </div>
                <div style={{ height: 32 }}/>
                <Button
                    style={styles.button}
                    variant={'soft'}
                >
                    <Icon type={'Activity'} bold/>
                    <div style={{ width: 8 }}/>
                   View Analytics
                </Button>
            </div>
        </div>
    )
}

const Contents = ({ data, onDisclose }) => {
    return (
        <div style={styles.wrapper}>
            {data.map((i, idx) => (
                <ContentItem
                    item={i}
                    key={i.id}
                    onDisclose={() => onDisclose(i)}
                />
            ))}
        </div>
    )
}

ContentItem.propTypes = {
    item: PropTypes.object.isRequired,
    onDisclose: PropTypes.func.isRequired,
}

ContentMetric.propTypes = {
    value: PropTypes.string.isRequired,
    iconType: PropTypes.string.isRequired,
}

Contents.propTypes = {
    data: PropTypes.array,
    onDisclose: PropTypes.func,
}

Contents.defaultProps = {
    data: [],
    onDisclose: () => null,
}

export default Contents

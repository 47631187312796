import PropTypes from 'prop-types'

import Text from 'components/Text'
import Icon from 'components/Iconly'
import { convertNumber, makePercentage } from 'lib/numbers'
import ChartLine from './ChartLine'

const styles = {
    wrapper: {
        background: '#fff',
        // boxShadow: '0px 0px 10px rgba(205, 213, 231, 0.4)',
        // border: '1px solid #EBF0F9',
        paddingBottom: 15,
        paddingTop: 15,
        borderRadius: 15,
        boxShadow: '0px 0px 25px rgba(205, 213, 231, 0.3)',
    },
    title: {
        marginLeft: 15,
    },
    line: {
        background: '#EBF0F9',
        height: 1,
        width: '100%',
    },
    cardWrapper: {
        display: 'flex',
        alignItems: 'center',
        // border: '1px solid #DAE6FD',
        background: '#fff',
        // boxShadow: '0px 0px 10px rgba(205, 213, 231, 0.4)',
        borderRadius: 10,
        padding: '15px 0px 15px 15px',
        width: '100%',
    },
    cardIconWrapper: {
        borderRadius: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 35,
        width: 35,
        fontSize: 16,
    },
    cardsWrapper: {
        display: 'flex',
        padding: 15,
        justifyContent: 'space-between',
    },
}

// eslint-disable-next-line
const Card = ({ label, value, icon, iconBg, iconColor }) => {
    return (
        <div style={styles.cardWrapper}>
            <div style={{
                ...styles.cardIconWrapper,
                backgroundColor: iconBg || '#ECFCFF',
                color: iconColor || '#68ADBA',
            }}>
                <Icon type={icon} bold/>
            </div>
            <div style={{ width: 10 }}/>
            <div>
                <Text
                    children={label}
                    color={'grey2'}
                    size={'p2'}
                />
                <Text
                    children={value}
                    color={'black'}
                    weight={'bold'}
                    size={'h4'}
                />
            </div>
        </div>
    )
}

const Views = ({ count, avgCount, data, name, dataKey, icon }) => {
    const averageDiff = count ? ((count - avgCount) / count) : 0

    return (
        <div style={styles.wrapper}>
            <div style={styles.title}>
                <Text
                    children={name}
                    size={'h6'}
                    weight={'bold'}
                />
            </div>
            <div style={{ height: 15 }}/>
            <div style={styles.line}/>
            <div style={styles.cardsWrapper}>
                <Card
                    value={convertNumber(count)}
                    label={name}
                    icon={icon}
                    iconBg={'#F5F9FF'}
                    iconColor={'#2772FF'}

                />
                <div style={{ width: 30 }}/>
                <Card
                    value={averageDiff > 0 ? '+' + makePercentage(averageDiff) : makePercentage(averageDiff)}
                    label={'vs average'}
                    icon={averageDiff >= 0 ? 'Arrow-Up' : 'Arrow-Down'}
                    iconBg={averageDiff >= 0 ? '#E5F5EF' : '#FFF3F5'}
                    iconColor={averageDiff >= 0 ? '#00985F' : '#FF274B'}
                />
            </div>
            <div style={styles.line}/>
            <div style={{ height: 30 }}/>
            <ChartLine data={data} dataKey={dataKey} />
        </div>
    )
}

Views.propTypes = {
    data: PropTypes.array,
    count: PropTypes.number,
    avgCount: PropTypes.number,
    name: PropTypes.string,
    icon: PropTypes.string,
    dataKey: PropTypes.string,
}

Views.defaultProps = {
    data: [],
    count: 0,
    avgCount: 0,
    name: '',
    dataKey: '',
    icon: 'Show',
}

export default Views


import { runQuery } from 'features/feature-network'
import queries from './graphql'

const apiToken = process.env.REACT_APP_API_TOKEN

export const runQueryWithToken = (query, props) => (dispatch) =>
    dispatch(runQuery(query, { ...props, token: apiToken }))

export const getReport = (reportId) => async (dispatch) => {
    const res = await dispatch(runQueryWithToken(queries.getReport, { reportId }))
    return res.data.main.getReport
}

export const getReportContent = ({ contentId, vendor, reportId }) => async (dispatch) => {
    const res = await dispatch(runQueryWithToken(queries.getReportContent, { contentId, vendor, reportId }))
    return res.data.main.getReportContent
}


import CircularProgress from '@material-ui/core/CircularProgress'
import PropTypes from 'prop-types'

const Spinner = ({ color, size, thickness, disableShrink, wrapperStyle, variant, value }) => {
    return (
        <div style={wrapperStyle}>
            <CircularProgress
                style={{
                    color,
                }}
                size={size}
                thickness={thickness}
                disableShrink={disableShrink}
                variant={variant}
                value={value}
            />
        </div>
    )
}

Spinner.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
    thickness: PropTypes.number,
    wrapperStyle: PropTypes.object,
    disableShrink: PropTypes.bool,
    variant: PropTypes.string,
    value: PropTypes.number,
}

Spinner.defaultProps = {
    wrapperStyle: {},
    color: '#1B1F27',
    size: 40,
    thickness: 3.6,
    disableShrink: false,
    value: 0,
    variant: 'indeterminate',
}

export default Spinner

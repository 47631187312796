import PropTypes from 'prop-types'

import MetricGrid from 'components/MetricGrid'
import MetricCard from 'components/MetricCard'

import { convertNumber, makePercentage } from 'lib/numbers'

const MainMetrics = ({ views, reach, viewRate, likes, comments, engagement }) => {
    return (
        <MetricGrid columns={3}>
            <MetricCard
                icon={'Show'}
                title={'Views'}
                value={convertNumber(views)}
            />
            <MetricCard
                icon={'Profile'}
                title={'Unique Reach'}
                value={convertNumber(reach)}
            />
            <MetricCard
                icon={'Logout'}
                title={'View Rate'}
                value={makePercentage(viewRate)}
            />
            <MetricCard
                icon={'Heart'}
                title={'Likes'}
                value={convertNumber(likes)}
            />
            <MetricCard
                icon={'Chat'}
                title={'Comments'}
                value={convertNumber(comments)}
            />
            <MetricCard
                icon={'Star'}
                title={'Engagement Rate'}
                value={makePercentage(engagement)}
            />
        </ MetricGrid >
    )
}

MainMetrics.propTypes = {
    comments: PropTypes.number,
    views: PropTypes.number,
    engagement: PropTypes.number,
    likes: PropTypes.number,
    reach: PropTypes.number,
    viewRate: PropTypes.number,
}

MainMetrics.defaultProps = {
    comments: 0,
    reach: 0,
    followers: 0,
    engagement: 0,
    likes: 0,
    views: 0,
    viewRate: 0,
}

export default MainMetrics

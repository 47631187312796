
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import MobileLayout from './MobileLayout'
import DesktopLayout from './DesktopLayout'

const ReportContentUI = ({ activeContent, data, close }) => {
    const isOpen = activeContent !== null
    const contentData = isOpen && data[activeContent.id + activeContent.vendor]
    const isError = contentData === null
    const isLoading = contentData === undefined
    const vendor = isOpen ? activeContent.vendor : null

    const [ innerOpen, setInnerOpen ] = useState(false)

    useEffect(() => {
        if (isOpen && !innerOpen) setInnerOpen(true)

        window.addEventListener('popstate', onClose)
        return () => window.removeEventListener('popstate', onClose)
    }, [isOpen])

    const onClose = () => {
        setInnerOpen(false)
        setTimeout(close, 500)
    }

    const isDesktop = useMediaQuery('(min-width: 992px)')
    const isMobile = useMediaQuery('(max-width: 991px)')

    if (isDesktop) {
        return (
            <DesktopLayout
                isOpen={innerOpen}
                data={contentData || null}
                isError={isError}
                isLoading={isLoading}
                close={onClose}
                vendor={vendor}
            />
        )
    }

    if (isMobile) {
        return (
            <MobileLayout
                isOpen={innerOpen}
                data={contentData || null}
                isError={isError}
                isLoading={isLoading}
                close={onClose}
                vendor={vendor}
            />
        )
    }

    return null
}

ReportContentUI.propTypes = {
    activeContent: PropTypes.object,
    error: PropTypes.string,
    data: PropTypes.object,
    close: PropTypes.func,
}

ReportContentUI.defaultProps = {
    activeContent: null,
    error: null,
    data: null,
    close: () => null,
}

export default ReportContentUI

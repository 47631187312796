
import player from 'lottie-web/build/player/lottie_light.min'
import { useEffect } from 'react'
import PropTypes from 'prop-types'

const Lottie = ({ elementId, animationData, style, loop = true }) => {
    useEffect(() => {
        player.loadAnimation({
            container: document.querySelector(`#${elementId}`),
            loop: loop, // boolean
            autoplay: true, // boolean
            animationData: animationData,
            renderer: 'svg',
            name: elementId,
        })

        return () => player.destroy(elementId)
    }, [])

    return <div id={elementId} style={style} />
}

Lottie.propTypes = {
    elementId: PropTypes.string,
    style: PropTypes.object,
    animationData: PropTypes.any,
    loop: PropTypes.bool,
}

Lottie.defaultProps = {
    style: {},
    elementId: PropTypes.string,
    animationData: null,
    loop: true,
}

export default Lottie

import PropTypes from 'prop-types'
import dayjs from 'dayjs'

import Image from 'components/Image'
import Text from 'components/Text'
import Icon from 'components/Iconly'

const styles = {
    wrapper: {
        boxShadow: '0px 0px 25px rgba(205, 213, 231, 0.3)',
        background: '#fff',
        borderRadius: 10,
        padding: 10,
    },
    nameWrapper: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    infoWrapper: {
        display: 'flex',
    },
}

const convertDuration = (time) => {
    if (!time) return '0m'
    const minutes = Math.floor(time / 60)
    const seconds = time - minutes * 60
    return `${minutes}m ${Math.round(seconds)}s`
}

const ContentInfo = ({ vendor, pic, date, channelName, channelPic, url, title, duration }) => {
    return (
        <div style={styles.wrapper}>
            <Image
                src={pic}
                height={178}
                width={'100%'}
                borderRadius={10}
                objectFit={'cover'}
                fallbackType={'contentV1'}
            />
            <div style={{ height: 8 }}/>
            <Text
                children={title}
                truncated

                color={'grey2'}
                weight={'bold'}
                size={'p2'}
            />
            <div style={{ height: 16 }}/>
            <div style={styles.nameWrapper}>
                <Image
                    src={channelPic}
                    height={26}
                    width={26}
                    borderRadius={'100%'}
                    objectFit={'cover'}
                />
                <div style={{ minWidth: 5, width: 5 }}/>
                <Text
                    size={'p'}
                    weight={'medium'}
                    truncated
                    children={`@${channelName}`}
                    wrapperStyle={{ width: '90%' }}
                />
            </div>
            <div style={{ height: 8 }}/>
            <div style={styles.infoWrapper}>
                <Text
                    children={(
                        <div style={{ display: 'flex' }}>
                            <Icon type={'Calendar'} bold style={{ fontSize: 14 }}/>
                            <div style={{ width: 5 }}/>
                            <span>{dayjs(date).format('D MMM, YYYY')}</span>
                        </div>
                    )}
                    onClick={() => window.open(url)}
                    color={'grey2'}
                    weight={'bold'}
                    size={'p2'}
                />
                <div style={{ width: 16 }}/>
                <Text
                    children={(
                        <div style={{ display: 'flex' }}>
                            <Icon type={'Time-Circle'} bold style={{ fontSize: 14 }}/>
                            <div style={{ width: 5 }}/>
                            <span>{convertDuration(duration)}</span>
                        </div>
                    )}
                    color={'grey2'}
                    weight={'bold'}
                    size={'p2'}
                />
            </div>
            <div style={{ height: 16 }}/>
            <Text
                children={`View on ${vendor}`}
                onClick={() => window.open(url)}
                color={'link'}
                weight={'semiBold'}
                size={'p2'}
            />
        </div>
    )
}

ContentInfo.propTypes = {
    pic: PropTypes.string,
    vendor: PropTypes.string,
    date: PropTypes.string,
    channelName: PropTypes.string,
    channelPic: PropTypes.string,
    url: PropTypes.string,
    title: PropTypes.string,
    duration: PropTypes.number,
}

ContentInfo.defaultProps = {
    pic: '',
    vendor: '',
    date: '',
    channelName: '',
    channelPic: '',
    url: '',
    title: '',
    duration: 0,
}

export default ContentInfo


import PropTypes from 'prop-types'

import { convertNumber, makePercentage } from 'lib/numbers'

import MetricCard from './MetricCard'

const styles = {
    wrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gap: 16,
        padding: 16,
    },
    metricWrapper: {
        display: 'flex',
        alignItems: 'center',
        background: '#fff',
        boxShadow: '0px 0px 25px rgba(205, 213, 231, 0.3)',
        borderRadius: 10,
        padding: '20px 0px 20px 20px',
        width: '100%',
    },
    metricIconWrapper: {
        borderRadius: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
        width: 40,
        fontSize: 20,
    },
}

const OverviewGrid = ({ views, likes, comments, engagement }) => {
    return (
        <div style={styles.wrapper}>
            <MetricCard
                value={convertNumber(views)}
                title={'Total Views'}
                icon={'Show'}
            />
            <MetricCard
                value={convertNumber(likes)}
                title={'Likes'}
                icon={'Heart'}
            />
            <MetricCard
                value={convertNumber(comments)}
                title={'Comments'}
                icon={'Chat'}
            />
            <MetricCard
                value={makePercentage(engagement)}
                title={'Engagement'}
                icon={'Star'}
            />
        </div>
    )
}

OverviewGrid.propTypes = {
    views: PropTypes.number,
    likes: PropTypes.number,
    comments: PropTypes.number,
    engagement: PropTypes.number,
}

OverviewGrid.defaultProps = {
    views: 0,
    likes: 0,
    comments: 0,
    engagement: 0,
}

export default OverviewGrid


import { Switch, Route } from 'react-router-dom'

import { HomePage, ReportPage } from './pages'

const App = () => {
    return (
        <>
            <Switch>
                <Route
                    path={'/:reportId'}
                    component={ReportPage}
                />
                <Route
                    path={'/'}
                    component={HomePage}
                />
            </Switch>
        </>
    )
}

export default App


import Text from 'components/Text'
import logo from 'resources/images/logo-black.png'
import SadLottie from 'components/Lottie/Sad'

const styles = {
    wrapper: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        overflowX: 'hidden',
        overflowY: 'hidden',
    },
    innerWrapper: {
        boxShadow: '0px 5px 20px rgba(205, 213, 231, 0.7)',
        borderRadius: 10,
        margin: '30px 15px',
        padding: '30px 15px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        maxWidth: 450,
    },
    lottie: {
        width: 150,
    },
}

// eslint-disable-next-line
const ErrorUI = ({ title, body }) => {
    return (
        <div style={styles.wrapper}>
            <div style={styles.innerWrapper}>
                <img
                    src={logo}
                    alt={'mysocial'}
                    style={{
                        width: 180,
                    }}
                />
                <div style={{ height: 90 }}/>
                <SadLottie loop style={styles.lottie}/>
                <div style={{ height: 30 }}/>
                <Text
                    children={title}
                    size={'h5'}
                    weight={'bold'}
                />
                <div style={{ height: 15 }}/>
                <Text
                    children={body}
                    size={'h6'}
                    weight={'normal'}
                    color={'text'}
                />
            </div>
        </div>
    )
}

export default ErrorUI

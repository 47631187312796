
import PropTypes from 'prop-types'

import Image from 'components/Image'
import VendorIcon from 'components/VendorIcon'
import Text from 'components/Text'
import Icon from 'components/Iconly'
import { convertNumber } from 'lib/numbers'

const styles = {
    wrapper: {
        padding: 16,
    },
    itemWrapper: {
        boxShadow: '0px 0px 25px rgba(205, 213, 231, 0.3)',
        background: '#fff',
        marginBottom: 16,
        borderRadius: 10,
        padding: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        position: 'relative',
        transition: 'transform 0.1s',
    },
    flex: {
        display: 'flex',
    },
    openWrapper: {
        background: '#F2F8FF',
        width: 30,
        height: 30,
        position: 'absolute',
        right: 8,
        top: 'auto',
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#2772FF',
    },
    removeWrapper: {
        background: '#fff',
        width: 30,
        height: 30,
        position: 'absolute',
        right: -10,
        top: -10,
        borderRadius: 20,
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#FF274B',
        boxShadow: '0px 0px 25px rgba(205, 213, 231, 0.3)',
    },
    metricWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '95%',
    },
    infoWrapper: {
        width: '50%',
    },
    contentMetricWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 16,
        color: '#A8B0C0',
    },
}

const ContentMetric = ({ value, iconType }) => {
    return (
        <div style={styles.contentMetricWrapper}>
            <Icon
                type={iconType}
                bold/>
            <div style={{ width: 5 }}/>
            <Text
                size={'p'}
                color={'grey2'}
                weight={'semiBold'}
                children={value}
            />
        </div>
    )
}

const ContentItem = ({ item, first, onDisclose }) => {
    let el = null

    return (
        <div
            className={first ? 'content' : ''}
            ref={e => (el = e)}
            onTouchStart={() => {
                el.style.opacity = 0.7
                el.style.transform = 'scale(0.95)'
            }}
            onTouchEnd={() => {
                el.style.opacity = 1
                el.style.transform = 'scale(1)'
            }}
            style={styles.itemWrapper}
            onClick={onDisclose}
        >
            <Image
                src={item.pic}
                height={100}
                width={100}
                borderRadius={10}
                objectFit={'cover'}
                fallbackType={'contentV1'}
            />
            <div style={{ width: 16 }}/>
            <div style={styles.infoWrapper}>
                <div style={styles.flex}>
                    <VendorIcon vendor={item.vendor} size={20}/>
                    <div style={{ width: 5 }}/>
                    <Text
                        weight={'medium'}
                        children={item.channelName || item.vendor}
                    />
                </div>
                <div style={{ height: 16 }}/>
                <div style={styles.metricWrapper}>
                    <ContentMetric
                        iconType={'Show'}
                        value={convertNumber(item.views)}/>
                    <ContentMetric
                        iconType={'Heart'}
                        value={convertNumber(item.likes)}
                    />
                    <ContentMetric
                        iconType={'Chat'}
                        value={convertNumber(item.comments)}/>
                </div>
            </div>
            <div
                style={styles.openWrapper}>
                <Icon type={'Arrow-Right-2'}/>
            </div>
        </div>
    )
}

const Contents = ({ data, onDisclose }) => {
    return (
        <div style={styles.wrapper}>
            {data.map((i, idx) => (
                <ContentItem
                    item={i}
                    key={i.id}
                    first={idx === 0}
                    onDisclose={() => onDisclose(i)}
                />
            ))}
        </div>
    )
}

ContentItem.propTypes = {
    item: PropTypes.object.isRequired,
    first: PropTypes.bool.isRequired,
    onDisclose: PropTypes.func.isRequired,
}

ContentMetric.propTypes = {
    value: PropTypes.string.isRequired,
    iconType: PropTypes.string.isRequired,
}

Contents.propTypes = {
    data: PropTypes.array,
    onDisclose: PropTypes.func,
}

Contents.defaultProps = {
    data: [],
    onDisclose: () => null,
}

export default Contents

import PropTypes from 'prop-types'

import Iconly from 'components/Iconly'
import Image from 'components/Image'
import Text from 'components/Text'
import GetVendorIcon from 'components/VendorIcon'

import { convertDate } from 'lib/dates'
import { colors } from 'styles'

const styles = {
    wrapper: {
        boxShadow: '0px 0px 25px rgba(205, 213, 231, 0.3)',
        // paddingBottom: 30,
        borderRadius: 15,
        background: '#fff',
        position: 'relative',
        // overflow: 'hidden',
        cursor: 'pointer',
    },
    innerWrapper: {
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'flex-start',
        // justifyContent: 'center',
        // marginTop: -55,
        // padding: '0px 30px',
        // textAlign: 'center',
        // borderRadius: 15,
    },
    tiktokInfoWrapper: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        paddingTop: 24,
        paddingLeft: 16,
        paddingBottom: 24,
        borderRadius: 15,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        background: 'linear-gradient(to top, rgba(0,0,0,0.9) 16%,rgba(0,0,0,0) 100%)',
        marginBottom: 3,
    },
    timeStamp: {
        position: 'absolute',
        top: 16,
        left: 16,
        fontSize: 14,
        zIndex: 5,
        color: '#fff',
        background: 'rgba(17, 17, 24, 0.4)',
        backdropFilter: 'blur(10px)',
        borderRadius: 10,
        padding: '7.5px 15px',
        willChange: 'opacity',
    },
    timeStamp2: {
        marginTop: 16,
        marginLeft: 16,
        color: colors.grey2,
        display: 'flex',
        alignItems: 'center',
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 16,
        paddingBottom: 16,
    },
    vendorIcon: {
        position: 'absolute',
        top: 22,
        right: 16,
    },
}

const ReportHeader = ({ vendor, data }) => {
    const handleClick = () => {
        window.open(data.url)
    }

    if (vendor === 'tiktok' || vendor === 'instagram') {
        let el2 = null
        return (
            <div
                ref={e => (el2 = e)}
                onMouseEnter={() => {
                    el2.style.opacity = 0.9
                }}
                onMouseLeave={() => {
                    el2.style.opacity = 1
                }}
                style={styles.wrapper}
                onClick={handleClick}
            >
                <Image
                    src={data.pic}
                    width={'100%'}
                    height={'100%'}
                    objectFit={'cover'}
                    borderRadius={15}
                />
                <div style={styles.tiktokInfoWrapper}>
                    <Image
                        height={32}
                        width={32}
                        src={data.channelPic}/>
                    <div style={{ width: 8 }}/>
                    <Text
                        color={'white'}
                        weight={'semiBold'}
                        size={'h5'}
                        children={`@${data.channelName}`}
                    />
                </div>
                <div style={styles.vendorIcon}>
                    <GetVendorIcon
                        vendor={vendor}
                    />
                </div>
                <div style={styles.timeStamp}>
                    Published {convertDate(data.published)}
                </div>
            </div>
        )
    }

    let el = null
    return (
        <div
            ref={e => (el = e)}
            onMouseEnter={() => {
                el.style.opacity = 0.9
            }}
            onMouseLeave={() => {
                el.style.opacity = 1
            }}
            style={styles.wrapper}
            onClick={handleClick}
        >
            <Image
                src={data.pic}
                width={340}
                height={191}
                objectFit={'cover'}
                borderRadius={'15px 15px 0px 0px'}
            />
            <div style={styles.timeStamp2}>
                <Iconly type={'Calendar'} bold/>
                <div style={{ width: 8 }}/>
                <Text
                    size={'p'}
                    color={'grey2'}
                    children={`Published ${convertDate(data.published)}`}
                />
            </div>
            <Text
                weight={'semiBold'}
                wrapperStyle={{ padding: 16 }}
                children={data.title}
            />
            <div style={styles.flex}>
                <Image
                    height={32}
                    width={32}
                    src={data.channelPic}/>
                <div style={{ width: 8 }}/>
                <Text
                    weight={'semiBold'}
                    children={data.channelName}
                />
            </div>
            <div style={styles.vendorIcon}>
                <GetVendorIcon
                    vendor={vendor}
                />
            </div>
        </div>
    )
}

ReportHeader.propTypes = {
    data: PropTypes.object.isRequired,
    vendor: PropTypes.string.isRequired,
}

export default ReportHeader

import PropTypes from 'prop-types'

import MetricGrid from 'components/MetricGrid'
import MetricCard from 'components/MetricCard'

import { convertNumber, makePercentage } from 'lib/numbers'

const convertDuration = (time) => {
    if (!time) return '0m'
    const minutes = Math.floor(time / 60)
    const seconds = time - minutes * 60
    return `${minutes}m ${Math.round(seconds)}s`
}

const MainMetrics = ({ views, retention, viewDuration, likes, comments, engagement }) => {
    return (
        <MetricGrid columns={3}>
            <MetricCard
                icon={'Show'}
                title={'Views'}
                value={convertNumber(views)}
            />
            <MetricCard
                icon={'Show'}
                title={'Retention Rate'}
                value={makePercentage(retention)}
            />
            <MetricCard
                icon={'Logout'}
                title={'View Duration'}
                value={convertDuration(viewDuration)}
            />
            <MetricCard
                icon={'Heart'}
                title={'Likes'}
                value={convertNumber(likes)}
            />
            <MetricCard
                icon={'Chat'}
                title={'Comments'}
                value={convertNumber(comments)}
            />
            <MetricCard
                icon={'Star'}
                title={'Engagement Rate'}
                value={makePercentage(engagement)}
            />
        </ MetricGrid >
    )
}

MainMetrics.propTypes = {
    comments: PropTypes.number,
    views: PropTypes.number,
    engagement: PropTypes.number,
    likes: PropTypes.number,
    retention: PropTypes.number,
    viewDuration: PropTypes.number,
}

MainMetrics.defaultProps = {
    comments: 0,
    retention: 0,
    followers: 0,
    engagement: 0,
    likes: 0,
    views: 0,
    viewDuration: 0,
}

export default MainMetrics


import PropTypes from 'prop-types'

import Text from 'components/Text'
import { colors } from 'styles'
import { makePercentage } from 'lib/numbers'

const styles = {
    wrapper: {
        background: '#fff',
        paddingTop: 15,
        paddingBottom: 15,
        borderRadius: 15,
        boxShadow: '0px 0px 25px rgba(205, 213, 231, 0.3)',
    },
    title: {
        marginLeft: 15,
        marginBottom: 15,
    },
    barWrapper: {
        paddingTop: 15,
    },
    barInfoWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 9,
    },
    barName: {
        fontSize: 14,
        fontWeight: 600,
        color: colors.black,
    },
    barPercentage: {
        fontSize: 14,
        fontWeight: 500,
    },
    barDataWrapper: {
        height: 10,
        position: 'relative',
    },
    barDataActive: {
        backgroundColor: '#2772FF',
        height: 12.5,
        borderRadius: 5,
        width: '100%',
        position: 'absolute',

    },
    barDataBackground: {
        backgroundColor: colors.lightBlue,
        width: '100%',
        borderRadius: 5,
        height: 12.5,
    },
    line: {
        background: '#EBF0F9',
        height: 1,
        width: '100%',
    },
    padding: {
        paddingLeft: 15,
        paddingRight: 15,
    },
}

// eslint-disable-next-line
const Bar = ({ name, value }) => {
    if (!value) return null

    const perc = value < 0.1 ? makePercentage(value, 2) : makePercentage(value)

    return (
        <div style={styles.barWrapper}>
            <div style={styles.barInfoWrapper}>
                <div style={styles.barName}>{name}</div>
                <div style={styles.barPercentage}>{perc}</div>
            </div>
            <div style={styles.barDataWrapper}>
                <div style={{ ...styles.barDataActive, width: perc }} />
                <div style={styles.barDataBackground} />
            </div>
        </div>
    )
}

const Audience = ({ sources, countries, ages, genders, sourcesOS, devices, cities }) => {
    const sortDesc = (arr) => arr.sort((a, b) => (a.value > b.value ? -1 : 1))

    return (
        <div style={styles.wrapper}>
            <div style={styles.title}>
                <Text
                    children={'Demographics'}
                    size={'h6'}
                    weight={'bold'}
                />
            </div>
            <div style={styles.line}/>
            {genders && genders.male
                ? (
                    <div style={styles.padding}>
                        <div style={{ height: 30 }} />
                        <Text size={'h6'} weight={'bold'} children={'Gender'}/>
                        <div style={{ height: 15 }} />
                        <Bar name={'Male'} value={genders.male / 100}/>
                        <Bar name={'Female'} value={genders.female / 100}/>
                        {genders.other ? <Bar name={'Other'} value={genders.other / 100}/> : null}
                        <div style={{ height: 30 }}/>
                        <div style={styles.line}/>
                    </ div>
                )
                : null}
            {ages && ages.length
                ? (
                    <div style={styles.padding}>
                        <div style={{ height: 30 }} />
                        <Text size={'h6'} weight={'bold'} children={'Age'}/>
                        <div style={{ height: 15 }} />
                        {sortDesc(ages).map(i => (
                            <Bar key={i.type} name={i.type} value={i.value / 100}/>
                        ))}
                        <div style={{ height: 30 }}/>
                        <div style={styles.line}/>
                    </ div>
                )
                : null}
            {countries && countries.length
                ? (
                    <div style={styles.padding}>
                        <div style={{ height: 30 }} />
                        <Text size={'h6'} weight={'bold'} children={'Countries'}/>
                        <div style={{ height: 15 }} />
                        {countries.map(i => (
                            <Bar key={i.type} name={i.type} value={i.value / 100}/>
                        ))}
                        <div style={{ height: 30 }}/>
                        <div style={styles.line}/>
                    </ div>
                )
                : null}
            {cities && cities.length
                ? (
                    <div style={styles.padding}>
                        <div style={{ height: 30 }} />
                        <Text size={'h6'} weight={'bold'} children={'Cities'}/>
                        <div style={{ height: 15 }} />
                        {cities.map(i => (
                            <Bar key={i.type} name={i.type} value={i.value / 100}/>
                        ))}
                    </div>
                )
                : null}
            {sources && sources.length
                ? (
                    <div style={styles.padding}>
                        <div style={{ height: 30 }} />
                        <Text size={'h6'} weight={'bold'} children={'Traffic sources'}/>
                        <div style={{ height: 15 }} />
                        {sources.map(i => (
                            <Bar key={i.type} name={i.type} value={i.value / 100}/>
                        ))}
                        <div style={{ height: 30 }}/>
                        <div style={styles.line}/>
                    </div>
                )
                : null}
            {sourcesOS && sourcesOS.length
                ? (
                    <div style={styles.padding}>
                        <div style={{ height: 30 }} />
                        <Text size={'h6'} weight={'bold'} children={'Operating systems'}/>
                        <div style={{ height: 15 }} />
                        {sourcesOS.map(i => (
                            <Bar key={i.type} name={i.type} value={i.value / 100}/>
                        ))}
                        <div style={{ height: 30 }}/>
                        <div style={styles.line}/>
                    </div>
                )
                : null}
            {devices && devices.length
                ? (
                    <div style={styles.padding}>
                        <div style={{ height: 30 }} />
                        <Text size={'h6'} weight={'bold'} children={'Devices'}/>
                        <div style={{ height: 15 }} />
                        {devices.map(i => (
                            <Bar key={i.type} name={i.type} value={i.value / 100}/>
                        ))}
                    </div>
                )
                : null}
            <div style={{ height: 15 }}/>
        </div>
    )
}

Audience.propTypes = {
    cities: PropTypes.array,
    countries: PropTypes.array,
    ages: PropTypes.array,
    genders: PropTypes.object,
    sources: PropTypes.array,
    sourcesOS: PropTypes.array,
    devices: PropTypes.array,
}

Audience.defaultProps = {
    cities: [],
    countries: [],
    ages: [],
    sources: [],
    sourcesOS: [],
    devices: [],
    genders: null,
}

export default Audience


import PropTypes from 'prop-types'

import animationData from './resources/sad.json'
import Lottie from './Lottie'

const SadLottie = ({ style, loop }) => (
    <Lottie
        animationData={animationData}
        elementId={'sad-lottie'}
        loop={loop}
        style={style}
    />
)

SadLottie.propTypes = {
    style: PropTypes.object,
    loop: PropTypes.bool,
}

SadLottie.defaultProps = {
    style: {},
    loop: false,
}

export default SadLottie


import PropTypes from 'prop-types'

const RocketIcon = ({ color, size }) => {
    const params = {
        fill: 'none',
        stroke: 'none',
        xmlns: 'http://www.w3.org/2000/svg',
        viewBox: '0 0 24 24',
        height: String(size) + 'px',
        width: String(size) + 'px',
    }

    const body = (
        <>
            <g clipPath="url(#clip0_661:8708)">
                <path
                    d="M20.539 17.9566C20.3152 14.9043 18.8643 12.9663 17.6866 11.8781C17.5509 11.7527 17.416 11.636 17.2831 11.5267C17.2826 7.13923 15.8844 4.25244 14.7113 2.6033C13.4326 0.805654 12.1641 0.0605166 12.1107 0.0297323C12.0419 -0.009954 11.9573 -0.009954 11.8885 0.0297323C11.8351 0.0605166 10.5666 0.805733 9.28796 2.6033C8.1149 4.25244 6.71663 7.13919 6.7162 11.5267C6.58322 11.636 6.44835 11.7527 6.31263 11.8781C5.13494 12.9664 3.684 14.9044 3.4602 17.9567C3.45286 18.0562 3.48737 18.1545 3.55537 18.2276C3.62337 18.3008 3.71871 18.3423 3.81859 18.3423H8.33294C8.59 18.8325 8.85686 19.2275 9.11663 19.4907C9.15835 19.533 9.21529 19.5568 9.27467 19.5568H9.38486C9.5509 21.9191 11.4351 23.7295 11.519 23.8087C11.6538 23.9362 11.8267 23.9999 11.9996 23.9999C12.1724 23.9999 12.3453 23.9362 12.4801 23.8087C12.564 23.7294 14.4482 21.9191 14.6143 19.5568H14.7245C14.7839 19.5568 14.8409 19.533 14.8826 19.4907C15.1424 19.2275 15.4093 18.8325 15.6663 18.3423H20.1806C20.2805 18.3423 20.3758 18.3007 20.4438 18.2276C20.5118 18.1544 20.5464 18.0563 20.539 17.9566ZM11.9996 6.1497C13.0884 6.1497 13.971 7.03228 13.971 8.12107C13.971 9.20985 13.0884 10.0924 11.9996 10.0924C10.9109 10.0924 10.0283 9.20977 10.0283 8.12107C10.0283 7.03232 10.9109 6.1497 11.9996 6.1497ZM11.9997 22.2484C11.5386 21.6659 10.8976 20.6739 10.7874 19.5568H13.2119C13.1016 20.674 12.4604 21.6661 11.9997 22.2484Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_661:8708">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>

        </>
    )

    return <svg {...params} children={body} />
}

RocketIcon.propTypes = {
    color: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    size: PropTypes.number,
}

RocketIcon.defaultProps = {
    color: '#1B1F27',
    size: 25,
}

export default RocketIcon

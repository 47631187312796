
import PropTypes from 'prop-types'

import PostComparisons from '../components/PostComparisons'
import ChartLineSection from '../components/ChartLineSection'
import Audience from '../components/Audience'
import ContentInfo from './ContentInfo'
import MainMetrics from './MainMetrics'

const InstagramUI = ({ data }) => {
    const postsProps = {
        posts: data.postsList,
        tabs: [
            { key: 'views', label: 'Views' },
            { key: 'likes', label: 'Likes' },
            { key: 'comments', label: 'Comments' },
            { key: 'engagement', label: 'Engagement' },
        ].filter(i => i !== null),
    }

    const mainMetricsProps = {
        likes: data.likes,
        comments: data.comments,
        views: data.views,
        reach: data.reach,
        viewRate: data.viewRate,
        engagement: data.engagement,
    }

    const audienceProps = {
        ages: data.ages,
        countries: data.countries,
        cities: data.cities,
        genders: data.genders,
    }

    const postsLength = data.postsList.length
    const postsTotals = data.postsList.reduce((acc, curr) => ({
        ...acc,
        likes: Number(((acc && acc.likes) || 0) + curr.likes),
        comments: Number(((acc && acc.comments) || 0) + curr.comments),
        views: Number(((acc && acc.views) || 0) + curr.views),
        reach: Number(((acc && acc.reach) || 0) + curr.reach),
    }), {})

    const likesProps = {
        name: 'Likes',
        dataKey: 'likes',
        data: data.history,
        count: data.likes,
        avgCount: data.likes ? Number(postsTotals.likes / postsLength) : 0,
    }

    const commentsProps = {
        name: 'Comments',
        dataKey: 'comments',
        data: data.history,
        count: data.comments,
        avgCount: data.comments ? Number(postsTotals.comments / postsLength) : 0,
    }

    const viewsProps = {
        name: 'Views',
        dataKey: 'impressions',
        data: data.history,
        count: data.views,
        avgCount: data.views ? Number(postsTotals.views / postsLength) : 0,
    }

    const contentInfoProps = {
        pic: data.pic,
        vendor: 'Instagram',
        channelName: data.channelName,
        channelPic: data.channelPic,
        date: data.published,
        url: data.url,
    }

    return (
        <div>
            <div style={{ height: 15 }}/>
            <ContentInfo {...contentInfoProps}/>
            <div style={{ height: 30 }}/>
            <MainMetrics {...mainMetricsProps}/>
            <div style={{ height: 30 }}/>
            <PostComparisons {...postsProps} />
            <div style={{ height: 30 }}/>
            <ChartLineSection {...viewsProps} icon={'Show'}/>
            <div style={{ height: 30 }}/>
            <ChartLineSection {...likesProps} icon={'Heart'}/>
            <div style={{ height: 30 }}/>
            <ChartLineSection {...commentsProps} icon={'Chat'}/>
            <div style={{ height: 30 }}/>
            <Audience {...audienceProps}/>
        </div>
    )
}

InstagramUI.propTypes = {
    data: PropTypes.object.isRequired,
}

export default InstagramUI

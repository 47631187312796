
export default {
    getReport: `
        query getReport ($token: String, $reportId: ID!){
            main (token: $token) {
                getReport (reportId: $reportId)
            }
        }
    `,
    getReportContent: `
        query getReportContent ($token: String, $reportId: ID!, $contentId: ID!, $vendor: String!){
            main (token: $token) {
                getReportContent (reportId: $reportId, contentId: $contentId, vendor: $vendor)
            }
        }
    `,
}


import PropTypes from 'prop-types'

import MainMetrics from './MainMetrics'
import Retention from './Retention'
import PostComparisons from '../components/PostComparisons'
import ChartLineSection from '../components/ChartLineSection'
import Audience from '../components/Audience'

const YoutubeUI = ({ data }) => {
    const mainMetricsProps = {
        likes: data.likes,
        comments: data.comments,
        views: data.views,
        retention: data.retention,
        engagement: data.engagement,
        viewDuration: data.viewDuration,
    }

    const postsProps = {
        posts: data.postsList,
        tabs: [
            { key: 'views', label: 'Views' },
            { key: 'likes', label: 'Likes' },
            { key: 'comments', label: 'Comments' },
            { key: 'engagement', label: 'Engagement' },
        ].filter(i => i !== null),
        vendor: 'youtube',
    }

    const postsTotals = data.postsList.reduce((acc, curr) => ({
        ...acc,
        likes: Number(((acc && acc.likes) || 0) + curr.likes),
        comments: Number(((acc && acc.comments) || 0) + curr.comments),
        views: Number(((acc && acc.views) || 0) + curr.views),
        shares: Number(((acc && acc.shares) || 0) + curr.shares),
        retention: Number(((acc && acc.retention) || 0) + curr.retention),
    }), {})
    const postsLength = data.postsList.length

    const likesProps = {
        name: 'Likes',
        dataKey: 'likes',
        data: data.history,
        count: data.likes,
        avgCount: data.likes ? Number(postsTotals.likes / postsLength) : 0,
    }

    const commentsProps = {
        name: 'Comments',
        dataKey: 'comments',
        data: data.history,
        count: data.comments,
        avgCount: data.comments ? Number(postsTotals.comments / postsLength) : 0,
    }

    const viewsProps = {
        name: 'Views',
        dataKey: 'views',
        data: data.history,
        count: data.views,
        avgCount: data.views ? Number(postsTotals.views / postsLength) : 0,
    }

    const sharesProps = {
        name: 'Shares',
        dataKey: 'shares',
        data: data.history,
        count: data.shares,
        avgCount: data.shares ? Number(postsTotals.shares / postsLength) : 0,
    }

    const audienceProps = {
        ages: data.ages.map(i => ({ type: i.type, value: i.total })),
        countries: data.countries,
        genders: data.genders,
        sources: data.sources,
        sourcesOS: data.sourcesOS,
        devices: data.devices,
    }

    const retention = {
        data: data.retentionList,
        duration: data.duration,
        count: data.retention,
        avgCount: data.retention ? Number(postsTotals.retention / postsLength) : 0,
    }

    return (
        <div>
            <MainMetrics {...mainMetricsProps} />
            <div style={{ height: 30 }}/>
            <PostComparisons {...postsProps}/>
            <Audience {...audienceProps}/>
            <div style={{ height: 30 }}/>
            <Retention {...retention}/>
            <div style={{ height: 30 }}/>
            <ChartLineSection {...viewsProps} icon={'Show'}/>
            <div style={{ height: 30 }}/>
            <ChartLineSection {...likesProps} icon={'Heart'}/>
            <div style={{ height: 30 }}/>
            <ChartLineSection {...commentsProps} icon={'Chat'}/>
            <div style={{ height: 30 }}/>
            <ChartLineSection {...sharesProps} icon={'Send'}/>
            <div style={{ height: 60 }}/>
        </div>
    )
}

YoutubeUI.propTypes = {
    data: PropTypes.object.isRequired,
}

export default YoutubeUI

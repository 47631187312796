import PropTypes from 'prop-types'

import Text from 'components/Text'
import Iconly from 'components/Iconly'

const styles = {
    wrapper: {
        background: '#fff',
        padding: '30px',
        boxShadow: '0px 0px 46.6167px rgba(205, 213, 231, 0.5)',
        borderRadius: 15,
    },
    iconWrapper: {
        height: 30,
        width: 30,
        borderRadius: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    flexWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    value: {
        fontSize: 44,
        fontWeight: 800,
        margin: 0,
        lineHeight: 1,
    },
}
const MetricCard = ({ value, title, icon, iconBackground, iconColor }) => {
    return (
        <div style={styles.wrapper}>
            <div style={styles.flexWrapper}>
                <div style={{
                    ...styles.iconWrapper,
                    background: iconBackground || '#F2F8FF',
                    color: iconColor || '#2772FF',
                }}>
                    <Iconly
                        bold
                        type={icon}
                    />
                </div>
                <div style={{ width: 10 }}/>
                <Text
                    color={'text'}
                    weight={'semiBold'}
                    size={'h6'}
                    children={title}
                />
            </div>

            <div style={{ height: 30 }}/>
            <h3
                style={styles.value}
                children={value}
            />
        </div>
    )
}

MetricCard.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.string,
    ]).isRequired,
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    iconBackground: PropTypes.string,
    iconColor: PropTypes.string,
}

MetricCard.defaultProps = {
    iconBackground: '#F2F8FF',
    iconColor: '#2772FF',
}

export default MetricCard

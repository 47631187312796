import PropTypes from 'prop-types'

import Iconly from 'components/Iconly'
import Text from 'components/Text'
import logo from 'resources/images/logo-black.png'

import { convertTimeFromNow } from 'lib/dates'

const styles = {
    wrapper: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 5,
        background: '#fff',
        height: 60,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        boxShadow: '0px -4px 35px rgba(205, 213, 231, 0.4)',
    },
    innerWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: 1250,
        minWidth: 1024,
        width: 1260,
        margin: 'auto',
    },
    arrowIcon: {
        fontSize: 20,
    },
    imgWrapper: {
        width: '60%',
        display: 'flex',
        justifyContent: 'center',
    },
    backWrapper: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 300,
        cursor: 'pointer',
        transition: 'transform 0.1s',
    },
    lastUpdated: {
        fontWeight: 500,
        color: '#A8B0C0',
    },
}

// eslint-disable-next-line
const Header = ({ close, updatedAt }) => {
    let el = null

    return (
        <div>
            <div style={styles.wrapper}>
                <div style={styles.innerWrapper}>
                    <div
                        ref={e => (el = e)}
                        onMouseEnter={() => {
                            el.style.opacity = 0.8
                            el.style.transform = 'scale(1.1)'
                        }}
                        onMouseLeave={() => {
                            el.style.opacity = 1
                            el.style.transform = 'scale(1)'
                        }}
                        onClick={close}
                        style={styles.backWrapper}
                    >
                        <Iconly
                            type={'Arrow-Left'}
                            style={styles.arrowIcon}
                        />
                        <div style={{ width: 5 }}/>
                        <Text
                            children={'Back To Home'}
                            weight={'bold'}
                        />
                    </div>
                    <div style={styles.imgWrapper}>
                        <img
                            src={logo}
                            alt={'mysocial'}
                            style={{ height: 26 }}
                        />
                    </div>
                    <div
                        style={styles.lastUpdated}
                        children={`Last updated ${convertTimeFromNow(updatedAt)} ago`}
                    />
                </div>
            </div>
            <div style={{ height: 50 }}/>
        </div>
    )
}

Header.propTypes = {
    updatedAt: PropTypes.string,
}

Header.defaultProps = {
    updatedAt: new Date(),
}

export default Header

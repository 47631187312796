import PropTypes from 'prop-types'

import Text from 'components/Text'
import Bar from './Bar'

const styles = {
    wrapper: {
        padding: 40,
        background: '#fff',
        borderRadius: 15,
        boxShadow: '0px 0px 46.6167px rgba(205, 213, 231, 0.5)',
    },
}

const AudienceCard = ({ title, data }) => {
    const modifiedData = data.sort((a, b) => (a.value > b.value ? -1 : 1))

    return (
        <div style={styles.wrapper}>
            <Text
                children={title}
                size={'h5'}
                weight={'semiBold'}
            />
            <div style={{ height: 15 }}/>
            {modifiedData.slice(0, 5).map((i, idx) => {
                return (
                    <Bar
                        key={idx}
                        name={i.type}
                        perc={i.value / 100}
                        value={i.value}
                        showPercent
                    />
                )
            })}
        </div>
    )
}

AudienceCard.propTypes = {
    data: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
}

export default AudienceCard


import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { reports } from 'features/feature-reports'
import ReportUI from './components/ReportUI'
import ReportContentUI from './components/ReportContentUI'
import { Route } from 'react-router-dom'

const mapState = (state, { match, location }) => ({
    reportId: match.params.reportId,
})

const mapDispatch = (dispatch, { match, history }) => ({
    load: () => dispatch(reports.getReport(match.params.reportId)),
    loadContent: (contentId, vendor) => dispatch(reports.getReportContent(contentId, vendor)),
    openModal: () => history.push(`/${match.params.reportId}/content`),
})

const ReportPage = ({ load, loadContent, reportId, openModal }) => {
    const [ report, setReport ] = useState(null)
    const [ reportError, setReportError ] = useState(null)
    const [ reportContent, setReportContent ] = useState({})
    const [ activeContent, setActiveContent ] = useState(null)

    useEffect(() => {
        load().then(r => setReport(r)).catch(e => setReportError(e.message))
    }, [])

    const onContentClick = (content) => {
        openModal()
        setActiveContent(content)

        if (reportContent[content.id + content.vendor] !== undefined) {
            return
        }

        loadContent({
            reportId,
            contentId: content.id,
            vendor: content.vendor,
        })
            .then(r => {
                setReportContent({
                    ...reportContent,
                    [content.id + content.vendor]: r,
                })
            })
            .catch(e => setReportContent({
                ...reportContent,
                [content.id + content.vendor]: null,
            }))
    }

    const onCloseContent = () => {
        setActiveContent(null)
    }

    return (
        <>
            <ReportUI
                error={reportError}
                data={report}
                onContentClick={onContentClick}
            />
            <Route
                path={'/:reportId/content'}
                children={() => {
                    return <ReportContentUI
                        activeContent={activeContent}
                        data={reportContent}
                        close={onCloseContent}
                    />
                }}
            />
        </>
    )
}

ReportPage.propTypes = {
    load: PropTypes.func.isRequired,
    loadContent: PropTypes.func.isRequired,
    reportId: PropTypes.string.isRequired,
    openModal: PropTypes.func.isRequired,
}

export default connect(mapState, mapDispatch)(ReportPage)


import PropTypes from 'prop-types'

import PageDrawer from 'components/PageDrawer'
import Header from './Header'

import ErrorUI from '../../ErrorUI'
import LoadingUI from '../../LoadingUI'
import ReportHeader from './ReportHeader'
import TiktokUI from './TiktokUI'
import YoutubeUI from './YoutubeUI'
import InstagramUI from './InstagramUI'

const styles = {
    body: {
        backgroundColor: '#F8F9FA',
        width: '100%',
        height: '100%',
        overflowY: 'scroll',
        // overflowY: 'scroll',
        // overflowX: 'hidden',
        // WebkitOverflowScrolling: 'touch',
        // maxWidth: 800,
        // minWidth: 800,
        // margin: 'auto',
    },
    containerWrapper: {
        width: '100%',
        display: 'flex',
        // alignItems: 'flex-start',
        justifyContent: 'center',
        marginTop: 90,
        paddingLeft: 30,
        paddingRight: 30,
    },
    containerInnerWrapper: {
        position: 'relative',
        maxWidth: 1250,
        minWidth: 1024,
        width: 1260,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
    headerWrapper: {
        width: 340,
        position: 'sticky',
        top: 90,
        padding: 0,
        margin: 0,
    },
    analyticsWrapper: {
        maxWidth: 800,
        minWidth: 800,
    },
}

const ReportContentDesktop = ({ isOpen, isError, isLoading, data, close, vendor }) => {
    const body = () => {
        if (isError) {
            return (
                <ErrorUI
                    title={'Oops, this report content doesn\'t exist'}
                    body={' '}
                />
            )
        }

        if (isLoading) {
            return <LoadingUI />
        }

        if (!data) return null

        const getUI = () => {
            switch (vendor) {
                case 'instagram': return <InstagramUI data={data}/>
                case 'youtube': return <YoutubeUI data={data} />
                case 'tiktok': return <TiktokUI data={data} />
                default: return 'need UI for ' + vendor
            }
        }

        return (
            <div style={styles.containerWrapper}>
                <div style={styles.containerInnerWrapper}>
                    <div style={styles.headerWrapper}>
                        <ReportHeader
                            pic={data.pic}
                            vendor={vendor}
                            data={data}
                        />
                    </div>
                    <div style={{ width: 90, minWidth: 15 }}/>
                    <div style={styles.analyticsWrapper}>
                        {getUI()}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <PageDrawer direction={'right'} open={isOpen} onClose={close}>
            <Header close={close} updatedAt={data && data.lastUpdated}/>
            <div style={styles.body}>{body()}</div>
        </PageDrawer>
    )
}

ReportContentDesktop.propTypes = {
    isOpen: PropTypes.bool,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    data: PropTypes.object,
    close: PropTypes.func,
    vendor: PropTypes.string,
}

ReportContentDesktop.defaultProps = {
    isOpen: false,
    isError: false,
    isLoading: false,
    data: null,
    close: () => null,
    vendor: null,
}

export default ReportContentDesktop


import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import ErrorUI from '../ErrorUI'
import LoadingUI from '../LoadingUI'
import MobileLayout from './MobileLayout'
import DesktopLayout from './DesktopLayout'

const ReportUI = ({ error, data, onContentClick }) => {
    if (error) {
        return (
            <ErrorUI
                title={'Oops, this report doesn\'t exist'}
                body={'Please make sure reportId is correct and try again'}
            />
        )
    }

    if (!data) return <LoadingUI />

    const isDesktop = useMediaQuery('(min-width: 992px)')
    const isMobile = useMediaQuery('(max-width: 991px)')

    if (isDesktop) {
        return (
            <DesktopLayout
                data={data}
                onContentClick={onContentClick}
            />
        )
    }

    if (isMobile) {
        return (
            <MobileLayout
                data={data}
                onContentClick={onContentClick}
            />
        )
    }

    return null
}

ReportUI.propTypes = {
    error: PropTypes.string,
    data: PropTypes.object,
    onContentClick: PropTypes.func,
}

ReportUI.defaultProps = {
    error: null,
    data: null,
    onContentClick: () => null,
}

export default ReportUI
